<template>
  <SimpleLayout>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
          </el-col>
          <el-col :span="4">
            <el-input v-model="searchForm.nickname" placeholder="昵称"></el-input>
          </el-col>
          <el-col :span="12">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table row-key="id" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="openid" label="openid"></el-table-column>
        <el-table-column  prop="nickname" label="昵称" width="200" show-overflow-tooltip>
          <template #default="s">
            <div class="flex-def">
              <img v-if="s.row.avatar" :src="s.row.avatar | tomedia" style="width: 3rem;height: 3rem;border-radius: 2rem" alt="">
              <div style="margin-left: .5rem">
                <div>{{s.row.nickname}}</div>
                <div>{{s.row.phone}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column  prop="from_uid" label="注册邀请人">
          <template #default="s">
            <div v-if="s.row.from_user">
              <div class="y-desc">编号:<span style="color: initial">{{ s.row.from_user.id }}</span></div>
              <div class="y-desc">昵称:<span style="color: initial">{{ s.row.from_user.nickname }}</span></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column  prop="bonus" label="佣金"></el-table-column>
        <el-table-column  prop="total_cash" label="累计提现"></el-table-column>
        <el-table-column  prop="total_order" label="消费订单"></el-table-column>
        <el-table-column  prop="total_order_fee" label="消费总额"></el-table-column>

        <el-table-column width="100" label="操作">
          <template v-slot="s">
            <YUserDetail :uid="s.row.id">
              <span class="y-pointer">详情</span>
            </YUserDetail>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </SimpleLayout>
</template>

<script>

import YUserDetail from "@/components/shop/YUserDetail";
import SimpleLayout from "@/components/layouts/SimpleLayout";
export default {
  name: "manage",
  components: {
    SimpleLayout,
    YUserDetail,
  },

  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm:{
        phone:"",
        nickname:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    searchGo(){
      this.page = 1;
      this.list.length =0;
      this.load();
    },
    searchReset(){
      this.searchForm = {
        phone:"",
        nickname:"",
        invite_code:"",
      }
      this.searchGo()
    },
    load() {
      this.$u.api.user.search({
        page:this.page,
        nickname:this.searchForm.nickname,
        phone:this.searchForm.phone,
        group:"all",
      }).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>